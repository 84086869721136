export interface DeviceState {
  isDesktop: boolean
  isMobile: boolean
  isTablet: boolean
}

let userAgent: string = ''

if (typeof window !== 'undefined') {
  userAgent =
    navigator?.userAgent ||
    navigator?.vendor ||
    (window as any).opera ||
    undefined
}

const isMobileDevice = (): boolean => {
  const regexs = [
    /(Android)(.+)(Mobile)/i,
    /BlackBerry/i,
    /iPhone|iPod/i,
    /Opera Mini/i,
    /IEMobile/i,
  ]
  return regexs.some((b) => userAgent.match(b))
}

const isTabletDevice = (): boolean => {
  const regex = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/
  return regex.test(userAgent.toLowerCase())
}

const isDesktopDevice = (): boolean => !isMobileDevice() && !isTabletDevice()

const isDesktop = isDesktopDevice()
const isMobile = isMobileDevice()
const isTablet = isTabletDevice()

export const useDevice = (): DeviceState => ({
  isDesktop,
  isMobile,
  isTablet,
})
