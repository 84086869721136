export const defaultFieldNames = {
  // Captcha Settings:
  //           For the ALISI brand, the captcha key is ALISI.
  //           For the Alveo brand, the captcha key is forAlveo.
  orgId: '',
  // Org ID (oid): This field changes depending on the environment (test, production, etc.).
  oid: '',
  // return URL after submitting form
  retURL: `${process.env.GATSBY_SITE_URL}`,
  // Brand value
  brand: '',
  // Campaign Code: Only needed if the form is for a specific campaign. If it’s a general form, leave this field blank.
  campaignCode: '',
  // Preferred Seller: Only needed if the form is for a specific seller, otherwise leave it blank.
  preferredSeller: '',
  // Digital Lead: Set to a fixed value of 1.
  digitalLead: '',
  // ALISI: 1  - Everything else other then ALISI 0
  otherThanAlisi: '',
}

export const currentDate = JSON.stringify(new Date().getTime())

export const devValues = {
  orgId: '00DIp0000000Ro1',
  oid: '00DIp0000000Ro1',
  retURL: `${process.env.GATSBY_SITE_URL}/contact-us/?form-success=true`,
  brand: '00NIp000000aveU',
  campaignCode: '00NIp000000ahLi',
  preferredSeller: '00NIp000000aqNi',
  digitalLead: '00NIp000000b0Kt',
  otherThanAlisi: '00NIp000000b0ML',
}

export const masterValues = {
  orgId: '00DIn000000LPfs',
  oid: '00DIn000000LPfs',
  retURL: `${process.env.GATSBY_SITE_URL}contact-us/?form-success=true`,
  brand: '00NIn000000sBFt',
  campaignCode: '00NIn000000sBG3',
  preferredSeller: '00NIn000000sBGQ',
  digitalLead: '00NIn000000sBG9',
  otherThanAlisi: '00NIn000000sBFp',
}

export const qaValues = {
  orgId: '00DF8000000ElTI',
  oid: '00DF8000000ElTI',
  retURL: `${process.env.GATSBY_SITE_URL}/contact-us/?form-success=true`,
  brand: '00NF800000AWrhd',
  campaignCode: '00NF800000AWrhp',
  preferredSeller: '00NF800000AWrhs',
  digitalLead: '00NF800000AWrhq',
  otherThanAlisi: '00NF800000AWrho',
}

export const prodValues = {
  orgId: '00D28000001Gnqh',
  oid: '00D28000001Gnqh',
  retURL: `${process.env.GATSBY_SITE_URL}/contact-us/?form-success=true`,
  brand: '00NIh000000mMim',
  campaignCode: '00NIh000000mMiy',
  preferredSeller: '00NIh000000mMj1',
  digitalLead: '00NIh000000mMiz',
  otherThanAlisi: '00NIh000000mMix',
}

export const isProd =
  process.env.GATSBY_SITE_URL &&
  process.env.GATSBY_SITE_URL.includes('www.avidaland.com')

export const isQA =
  process.env.GATSBY_SITE_URL &&
  process.env.GATSBY_SITE_URL.includes('qa.d7fr7yekds24i.amplifyapp')

export const isMaster =
  process.env.GATSBY_SITE_URL &&
  process.env.GATSBY_SITE_URL.includes('master.d3riien6a42rml.amplifyapp')

export const countryCodeName = isProd
  ? '00N2x000008U6jN'
  : isQA
    ? '00NF800000AI5uv'
    : isMaster
      ? '00N2x000008U6jN'
      : '00N2x000008U6jN'

export const projectCodeName = '00N0K00000Jzwje'

export const ActionUrl = {
  url: isProd
    ? 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D28000001Gnqh'
    : `https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=${isQA ? qaValues.orgId : isMaster ? masterValues.orgId : '00DIp0000000Ro1'}`,
}

export const recordTypeOptions = [
  {
    label: 'Corporate',
    value: '012Ip000000L4gI',
    env: 'dev',
  },
  {
    label: 'Individual',
    value: '012Ip000000L3dN',
    env: 'dev',
  },

  {
    label: 'Corporate',
    value: '012In000000TSJM',
    env: 'master',
  },
  {
    label: 'Individual',
    value: '012In000000TSJN',
    env: 'master',
  },

  {
    label: 'Corporate',
    value: '012F8000001PwVy',
    env: 'qa',
  },
  {
    label: 'Individual',
    value: '012F8000001PwVz',
    env: 'qa',
  },
  {
    label: 'Corporate',
    value: '012Ih000000PF9S',
    env: 'prod',
  },
  {
    label: 'Individual',
    value: '012Ih000000PF9T',
    env: 'prod',
  },
]

export const CountryCode = [
  {
    label: 'US',
    value: 'US',
  },
  {
    label: 'AC',
    value: 'AC',
  },
  {
    label: 'AD',
    value: 'AD',
  },
  {
    label: 'AE',
    value: 'AE',
  },
  {
    label: 'AF',
    value: 'AF',
  },
  {
    label: 'AG',
    value: 'AG',
  },
  {
    label: 'AI',
    value: 'AI',
  },
  {
    label: 'AL',
    value: 'AL',
  },
  {
    label: 'AM',
    value: 'AM',
  },
  {
    label: 'AO',
    value: 'AO',
  },
  {
    label: 'AQ',
    value: 'AQ',
  },
  {
    label: 'AR',
    value: 'AR',
  },

  {
    label: 'AS',
    value: 'AS',
  },
  {
    label: 'AT',
    value: 'AT',
  },

  {
    label: 'AU',
    value: 'AU',
  },
  {
    label: 'AW',
    value: 'AW',
  },
  {
    label: 'AX',
    value: 'AX',
  },
  {
    label: 'AZ',
    value: 'AZ',
  },
  {
    label: 'BA',
    value: 'BA',
  },
  {
    label: 'BB',
    value: 'BB',
  },
  {
    label: 'BD',
    value: 'BD',
  },
  {
    label: 'BE',
    value: 'BE',
  },
  {
    label: 'BF',
    value: 'BF',
  },
  {
    label: 'BG',
    value: 'BG',
  },

  {
    label: 'BH',
    value: 'BH',
  },
  {
    label: 'BI',
    value: 'BI',
  },

  {
    label: 'BJ',
    value: 'BJ',
  },
  {
    label: 'BL',
    value: 'BL',
  },
  {
    label: 'BM',
    value: 'BM',
  },
  {
    label: 'BN',
    value: 'BN',
  },
  {
    label: 'BO',
    value: 'BO',
  },
  {
    label: 'BQ',
    value: 'BQ',
  },
  {
    label: 'BR',
    value: 'BR',
  },
  {
    label: 'BS',
    value: 'BS',
  },
  {
    label: 'BT',
    value: 'BT',
  },
  {
    label: 'BV',
    value: 'BV',
  },

  {
    label: 'BW',
    value: 'BW',
  },
  {
    label: 'BY',
    value: 'BY',
  },

  {
    label: 'BZ',
    value: 'BZ',
  },
  {
    label: 'CA',
    value: 'CA',
  },
  {
    label: 'CC',
    value: 'CC',
  },
  {
    label: 'CD',
    value: 'CD',
  },
  {
    label: 'CF',
    value: 'CF',
  },
  {
    label: 'CG',
    value: 'CG',
  },
  {
    label: 'CH',
    value: 'CH',
  },
  {
    label: 'CI',
    value: 'CI',
  },
  {
    label: 'CK',
    value: 'CK',
  },
  {
    label: 'CL',
    value: 'CL',
  },

  {
    label: 'CM',
    value: 'CM',
  },
  {
    label: 'CN',
    value: 'CN',
  },

  {
    label: 'CO',
    value: 'CO',
  },
  {
    label: 'CR',
    value: 'CR',
  },
  {
    label: 'CU',
    value: 'CU',
  },
  {
    label: 'CV',
    value: 'CV',
  },
  {
    label: 'CW',
    value: 'CW',
  },
  {
    label: 'CX',
    value: 'CX',
  },
  {
    label: 'CY',
    value: 'CY',
  },
  {
    label: 'CZ',
    value: 'CZ',
  },
  {
    label: 'DE',
    value: 'DE',
  },
  {
    label: 'DJ',
    value: 'DJ',
  },

  {
    label: 'DK',
    value: 'DK',
  },
  {
    label: 'DM',
    value: 'DM',
  },

  {
    label: 'DO',
    value: 'DO',
  },
  {
    label: 'DZ',
    value: 'DZ',
  },
  {
    label: 'EC',
    value: 'EC',
  },
  {
    label: 'EE',
    value: 'EE',
  },
  {
    label: 'EG',
    value: 'EG',
  },
  {
    label: 'ER',
    value: 'ER',
  },
  {
    label: 'ES',
    value: 'ES',
  },
  {
    label: 'ET',
    value: 'ET',
  },
  {
    label: 'FI',
    value: 'FI',
  },
  {
    label: 'FJ',
    value: 'FJ',
  },

  {
    label: 'FK',
    value: 'FK',
  },
  {
    label: 'FM',
    value: 'FM',
  },

  {
    label: 'FO',
    value: 'FO',
  },
  {
    label: 'FR',
    value: 'FR',
  },
  {
    label: 'GA',
    value: 'GA',
  },
  {
    label: 'GB',
    value: 'GB',
  },
  {
    label: 'GD',
    value: 'GD',
  },
  {
    label: 'GE',
    value: 'GE',
  },
  {
    label: 'GF',
    value: 'GF',
  },
  {
    label: 'GG',
    value: 'GG',
  },
  {
    label: 'GH',
    value: 'GH',
  },
  {
    label: 'GI',
    value: 'GI',
  },
  {
    label: 'GL',
    value: 'GL',
  },
  {
    label: 'GM',
    value: 'GM',
  },

  {
    label: 'GN',
    value: 'GN',
  },
  {
    label: 'GP',
    value: 'GP',
  },
  {
    label: 'GQ',
    value: 'GQ',
  },
  {
    label: 'GR',
    value: 'GR',
  },
  {
    label: 'GS',
    value: 'GS',
  },
  {
    label: 'GT',
    value: 'GT',
  },
  {
    label: 'GU',
    value: 'GU',
  },
  {
    label: 'GW',
    value: 'GW',
  },
  {
    label: 'GY',
    value: 'GY',
  },
  {
    label: 'HK',
    value: 'HK',
  },
  {
    label: 'HM',
    value: 'HM',
  },
  {
    label: 'HN',
    value: 'HN',
  },

  {
    label: 'HR',
    value: 'HR',
  },
  {
    label: 'HT',
    value: 'HT',
  },
  {
    label: 'HU',
    value: 'HU',
  },
  {
    label: 'ID',
    value: 'ID',
  },
  {
    label: 'IE',
    value: 'IE',
  },
  {
    label: 'IL',
    value: 'IL',
  },
  {
    label: 'IM',
    value: 'IM',
  },
  {
    label: 'IN',
    value: 'IN',
  },
  {
    label: 'IO',
    value: 'IO',
  },
  {
    label: 'IQ',
    value: 'IQ',
  },
  {
    label: 'IR',
    value: 'IR',
  },
  {
    label: 'IS',
    value: 'IS',
  },

  {
    label: 'IT',
    value: 'IT',
  },
  {
    label: 'JE',
    value: 'JE',
  },
  {
    label: 'JM',
    value: 'JM',
  },
  {
    label: 'JO',
    value: 'JO',
  },
  {
    label: 'JP',
    value: 'JP',
  },
  {
    label: 'KE',
    value: 'KE',
  },
  {
    label: 'KG',
    value: 'KG',
  },
  {
    label: 'KH',
    value: 'KH',
  },
  {
    label: 'KI',
    value: 'KI',
  },
  {
    label: 'KM',
    value: 'KM',
  },
  {
    label: 'KN',
    value: 'KN',
  },
  {
    label: 'KP',
    value: 'KP',
  },

  {
    label: 'KR',
    value: 'KR',
  },
  {
    label: 'KW',
    value: 'KW',
  },
  {
    label: 'KY',
    value: 'KY',
  },
  {
    label: 'KZ',
    value: 'KZ',
  },
  {
    label: 'LA',
    value: 'LA',
  },
  {
    label: 'LB',
    value: 'LB',
  },
  {
    label: 'LC',
    value: 'LC',
  },
  {
    label: 'LI',
    value: 'LI',
  },
  {
    label: 'LK',
    value: 'LK',
  },
  {
    label: 'LR',
    value: 'LR',
  },
  {
    label: 'LS',
    value: 'LS',
  },
  {
    label: 'LT',
    value: 'LT',
  },

  {
    label: 'LU',
    value: 'LU',
  },
  {
    label: 'LV',
    value: 'LV',
  },
  {
    label: 'LY',
    value: 'LY',
  },
  {
    label: 'MA',
    value: 'MA',
  },
  {
    label: 'MC',
    value: 'MC',
  },
  {
    label: 'MD',
    value: 'MD',
  },
  {
    label: 'ME',
    value: 'ME',
  },
  {
    label: 'MF',
    value: 'MF',
  },
  {
    label: 'MG',
    value: 'MG',
  },
  {
    label: 'MH',
    value: 'MH',
  },
  {
    label: 'MK',
    value: 'MK',
  },
  {
    label: 'ML',
    value: 'ML',
  },

  {
    label: 'MN',
    value: 'MN',
  },
  {
    label: 'MO',
    value: 'MO',
  },
  {
    label: 'MP',
    value: 'MP',
  },
  {
    label: 'MQ',
    value: 'MQ',
  },
  {
    label: 'MR',
    value: 'MR',
  },
  {
    label: 'MS',
    value: 'MS',
  },
  {
    label: 'MT',
    value: 'MT',
  },
  {
    label: 'MU',
    value: 'MU',
  },
  {
    label: 'MV',
    value: 'MV',
  },
  {
    label: 'MW',
    value: 'MW',
  },
  { label: 'MX', value: 'MX' },
  { label: 'MY', value: 'MY' },
  { label: 'MZ', value: 'MZ' },
  { label: 'NA', value: 'NA' },
  { label: 'NC', value: 'NC' },
  { label: 'NE', value: 'NE' },
  { label: 'NF', value: 'NF' },
  { label: 'NG', value: 'NG' },
  { label: 'NI', value: 'NI' },
  { label: 'NL', value: 'NL' },
  { label: 'NO', value: 'NO' },
  { label: 'NP', value: 'NP' },
  { label: 'NR', value: 'NR' },
  { label: 'NU', value: 'NU' },
  { label: 'NZ', value: 'NZ' },
  { label: 'OM', value: 'OM' },
  { label: 'PA', value: 'PA' },
  { label: 'PE', value: 'PE' },
  { label: 'PF', value: 'PF' },
  { label: 'PG', value: 'PG' },
  { label: 'PH', value: 'PH' },
  { label: 'PK', value: 'PK' },
  { label: 'PL', value: 'PL' },
  { label: 'PM', value: 'PM' },
  { label: 'PN', value: 'PN' },
  { label: 'PR', value: 'PR' },
  { label: 'PS', value: 'PS' },
  { label: 'PT', value: 'PT' },
  { label: 'PW', value: 'PW' },
  { label: 'PY', value: 'PY' },
  { label: 'QA', value: 'QA' },
  { label: 'RE', value: 'RE' },
  { label: 'RO', value: 'RO' },
  { label: 'RS', value: 'RS' },
  { label: 'RU', value: 'RU' },
  { label: 'RW', value: 'RW' },
  { label: 'SA', value: 'SA' },
  { label: 'SB', value: 'SB' },
  { label: 'SC', value: 'SC' },
  { label: 'SD', value: 'SD' },
  { label: 'SE', value: 'SE' },
  { label: 'SG', value: 'SG' },
  { label: 'SH', value: 'SH' },
  { label: 'SI', value: 'SI' },
  { label: 'SJ', value: 'SJ' },
  { label: 'SK', value: 'SK' },
  { label: 'SL', value: 'SL' },
  { label: 'SM', value: 'SM' },
  { label: 'SN', value: 'SN' },
  { label: 'SO', value: 'SO' },
  { label: 'SR', value: 'SR' },
  { label: 'SS', value: 'SS' },
  { label: 'ST', value: 'ST' },
  { label: 'SV', value: 'SV' },
  { label: 'SX', value: 'SX' },
  { label: 'SY', value: 'SY' },
  { label: 'SZ', value: 'SZ' },
  { label: 'TA', value: 'TA' },
  { label: 'TC', value: 'TC' },
  { label: 'TD', value: 'TD' },
  { label: 'TG', value: 'TG' },
  { label: 'TH', value: 'TH' },
  { label: 'TJ', value: 'TJ' },
  { label: 'TK', value: 'TK' },
  { label: 'TL', value: 'TL' },
  { label: 'TM', value: 'TM' },
  { label: 'TN', value: 'TN' },
  { label: 'TO', value: 'TO' },
  { label: 'TR', value: 'TR' },
  { label: 'TT', value: 'TT' },
  { label: 'TV', value: 'TV' },
  { label: 'TW', value: 'TW' },
  { label: 'TZ', value: 'TZ' },
  { label: 'UA', value: 'UA' },
  { label: 'UG', value: 'UG' },
  { label: 'UY', value: 'UY' },
  { label: 'UZ', value: 'UZ' },
  { label: 'VA', value: 'VA' },
  { label: 'VC', value: 'VC' },
  { label: 'VE', value: 'VE' },
  { label: 'VG', value: 'VG' },
  { label: 'VI', value: 'VI' },
  { label: 'VN', value: 'VN' },
  { label: 'VU', value: 'VU' },
  { label: 'WF', value: 'WF' },
  { label: 'WS', value: 'WS' },
  { label: 'YE', value: 'YE' },
  { label: 'YT', value: 'YT' },
  { label: 'ZA', value: 'ZA' },
  { label: 'ZM', value: 'ZM' },
  { label: 'ZW', value: 'ZW' },
]

export const PropertyCode = [
  { label: 'Aldea Grove Estates', value: 'Aldea Grove Estates' },
  { label: 'Atria', value: 'Atria' },
  { label: 'Averdeen Estates Nuvali', value: 'Averdeen Estates Nuvali' },
  {
    label: 'Avida Cityflex Towers BGC',
    value: 'Avida Cityflex Towers BGC',
  },
  {
    label: 'Avida Northdale Settings Alviera',
    value: 'Avida Northdale Settings Alviera',
  },
  {
    label: 'Avida Parkfield Settings Pulilan',
    value: 'Avida Parkfield Settings Pulilan',
  },
  {
    label: 'Avida Parkway Settings Nuvali',
    value: 'Avida Parkway Settings Nuvali',
  },
  {
    label: 'Avida Residences Dasmariñas',
    value: 'Avida Residences Dasmariñas',
  },
  {
    label: 'Avida Residences San Fernando',
    value: 'Avida Residences San Fernando',
  },
  {
    label: 'Avida Residences San Isidro',
    value: 'Avida Residences San Isidro',
  },
  {
    label: 'Avida Residences St. Alexandra',
    value: 'Avida Residences St. Alexandra',
  },
  {
    label: 'Avida Residences St. Gabriel',
    value: 'Avida Residences St. Gabriel',
  },
  {
    label: 'Avida Residences Sta. Arcadia',
    value: 'Avida Residences Sta. Arcadia',
  },
  {
    label: 'Avida Residences Sta. Catalina',
    value: 'Avida Residences Sta. Catalina',
  },
  {
    label: 'Avida Residences Sta. Monica',
    value: 'Avida Residences Sta. Monica',
  },
  { label: 'Avida Settings Altaraza', value: 'Avida Settings Altaraza' },
  { label: 'Avida Settings Alviera', value: 'Avida Settings Alviera' },
  { label: 'Avida Settings Batangas', value: 'Avida Settings Batangas' },
  {
    label: 'Avida Settings Cabanatuan',
    value: 'Avida Settings Cabanatuan',
  },
  { label: 'Avida Settings Cavite', value: 'Avida Settings Cavite' },
  {
    label: 'Avida Settings Greendale Alviera',
    value: 'Avida Settings Greendale Alviera',
  },
  { label: 'Avida Settings Lipa', value: 'Avida Settings Lipa' },
  { label: 'Avida Settings Nuvali', value: 'Avida Settings Nuvali' },
  {
    label: 'Avida Settings Tuguegarao',
    value: 'Avida Settings Tuguegarao',
  },
  {
    label: 'Avida Shophouse, Technopark',
    value: 'Avida Shophouse, Technopark',
  },
  {
    label: 'Avida Southfield Settings Nuvali',
    value: 'Avida Southfield Settings Nuvali',
  },
  { label: 'Avida Towers Abreeza', value: 'Avida Towers Abreeza' },
  { label: 'Avida Towers Alabang', value: 'Avida Towers Alabang' },
  { label: 'Avida Towers Altura', value: 'Avida Towers Altura' },
  { label: 'Avida Towers Ardane', value: 'Avida Towers Ardane' },
  { label: 'Avida Towers Aspira', value: 'Avida Towers Aspira' },
  { label: 'Avida Towers Asten', value: 'Avida Towers Asten' },
  {
    label: 'Avida Towers Astrea South',
    value: 'Avida Towers Astrea South',
  },
  {
    label: 'Avida Towers Bgc 34Th St',
    value: 'Avida Towers Bgc 34Th St',
  },
  {
    label: 'Avida Towers Bgc 9Th Avenue',
    value: 'Avida Towers Bgc 9Th Avenue',
  },
  { label: 'Avida Towers Cebu', value: 'Avida Towers Cebu' },
  { label: 'Avida Towers Centera', value: 'Avida Towers Centera' },
  { label: 'Avida Towers Cloverleaf', value: 'Avida Towers Cloverleaf' },
  { label: 'Avida Towers Davao', value: 'Avida Towers Davao' },
  { label: 'Avida Towers Intima', value: 'Avida Towers Intima' },
  {
    label: 'Avida Towers Makati Southpoint',
    value: 'Avida Towers Makati Southpoint',
  },
  {
    label: 'Avida Towers Makati West',
    value: 'Avida Towers Makati West',
  },
  { label: 'Avida Towers New Manila', value: 'Avida Towers New Manila' },
  {
    label: 'Avida Towers One Union Place',
    value: 'Avida Towers One Union Place',
  },
  { label: 'Avida Towers Prime Taft', value: 'Avida Towers Prime Taft' },
  { label: 'Avida Towers Riala', value: 'Avida Towers Riala' },
  { label: 'Avida Towers San Lazaro', value: 'Avida Towers San Lazaro' },
  {
    label: 'Avida Towers San Lorenzo',
    value: 'Avida Towers San Lorenzo',
  },
  { label: 'Avida Towers Serra', value: 'Avida Towers Serra' },
  { label: 'Avida Towers Sola', value: 'Avida Towers Sola' },
  { label: 'Avida Towers Sucat', value: 'Avida Towers Sucat' },
  { label: 'Avida Towers Turf Bgc', value: 'Avida Towers Turf Bgc' },
  { label: 'Avida Towers Verge', value: 'Avida Towers Verge' },
  { label: 'Avida Towers Vireo', value: 'Avida Towers Vireo' },
  {
    label: 'Avida Towers Vita In Vertis North',
    value: 'Avida Towers Vita In Vertis North',
  },
  {
    label: 'Avida Town Center Sta Catalina',
    value: 'Avida Town Center Sta Catalina',
  },
  {
    label: 'Avida Verra Settings Vermosa',
    value: 'Avida Verra Settings Vermosa',
  },
  {
    label: 'Avida Village Cerise Nuvali',
    value: 'Avida Village Cerise Nuvali',
  },
  { label: 'Avida Village Iloilo', value: 'Avida Village Iloilo' },
  {
    label: 'Avida Village North Point',
    value: 'Avida Village North Point',
  },
  {
    label: 'Avida Village Santa Cecilia',
    value: 'Avida Village Santa Cecilia',
  },
  {
    label: 'Avida Woodhill Settings Nuvali',
    value: 'Avida Woodhill Settings Nuvali',
  },
  { label: 'Capital House', value: 'Capital House' },
  { label: 'Centralis Towers', value: 'Centralis Towers' },
  { label: 'Centrio Tower', value: 'Centrio Tower' },
  { label: 'Greenlane Settings', value: 'Greenlane Settings' },
  { label: 'Hacienda Sta. Monica', value: 'Hacienda Sta. Monica' },
  {
    label: 'Hillcrest Estates Nuvali',
    value: 'Hillcrest Estates Nuvali',
  },
  { label: 'Madera Grove Estates', value: 'Madera Grove Estates' },
  { label: 'One Antonio', value: 'One Antonio' },
  { label: 'One Palm', value: 'One Palm' },
  { label: 'One Park Drive', value: 'One Park Drive' },
  {
    label: 'Parklane Settings Vermosa',
    value: 'Parklane Settings Vermosa',
  },
  { label: 'Patio Madrigal', value: 'Patio Madrigal' },
  {
    label: 'Ridgeview Estates Nuvali',
    value: 'Ridgeview Estates Nuvali',
  },
  { label: 'Riego De Dios Village', value: 'Riego De Dios Village' },
  { label: 'San Alfonso Homes', value: 'San Alfonso Homes' },
  { label: 'San Francisco Village', value: 'San Francisco Village' },
  { label: 'San Ignacio Estates', value: 'San Ignacio Estates' },
  { label: 'San Isidro Village', value: 'San Isidro Village' },
  { label: 'San Jose Village', value: 'San Jose Village' },
  { label: 'San Juan Estates', value: 'San Juan Estates' },
  { label: 'San Rafael Estate', value: 'San Rafael Estate' },
  { label: 'San Rafael Estates', value: 'San Rafael Estates' },
  { label: 'Santa Barbara Homes', value: 'Santa Barbara Homes' },
  { label: 'Santa Isabel Village', value: 'Santa Isabel Village' },
  { label: 'Santarosa Estates', value: 'Santarosa Estates' },
  { label: 'Santarosa Village', value: 'Santarosa Village' },
  { label: 'Sentria Storeys Vermosa', value: 'Sentria Storeys Vermosa' },
  { label: 'Serin East Tagaytay', value: 'Serin East Tagaytay' },
  { label: 'Serin Flats', value: 'Serin Flats' },
  { label: 'Serin West Tagaytay', value: 'Serin West Tagaytay' },
  {
    label: 'Solara Park Storeys Nuvali',
    value: 'Solara Park Storeys Nuvali',
  },
  { label: 'South Park District', value: 'South Park District' },
  {
    label: 'Southdale Settings Nuvali',
    value: 'Southdale Settings Nuvali',
  },
  { label: 'Southgrove Estates', value: 'Southgrove Estates' },
  { label: 'St. Joseph Homes', value: 'St. Joseph Homes' },
  { label: 'St. Jude Village', value: 'St. Jude Village' },
  { label: 'The Montane', value: 'The Montane' },
  { label: 'Two Antonio', value: 'Two Antonio' },
  {
    label: 'Vermont Settings Alviera',
    value: 'Vermont Settings Alviera',
  },
]
