import React, { useEffect, useState } from 'react'
import {
  defaultFieldNames,
  currentDate,
  prodValues,
  qaValues,
  masterValues,
  devValues,
  isMaster,
  isQA,
  isProd,
} from '../SalesForceForm.utils'

function HiddenFields() {
  const [fieldNames, setFieldNames] = useState(defaultFieldNames)

  useEffect(() => {
    if (isProd) {
      setFieldNames(prodValues)
    } else if (isQA) {
      setFieldNames(qaValues)
    } else if (isMaster) {
      setFieldNames(masterValues)
    } else {
      setFieldNames(devValues)
    }
  }, [])

  console.log('Hidden fieldNames', fieldNames)

  return (
    <>
      {/* Add lead source (hidden fixed value) */}
      <input
        type="hidden"
        id="lead_source"
        name="lead_source"
        value="Web Form"
      />

      <input
        type="hidden"
        name="captcha_settings"
        value={`{"keyname":"Avida","fallback":"true","orgId":"${
          fieldNames?.orgId
        }","ts":"${currentDate}"}`}
      />

      <input type="hidden" name="oid" value={fieldNames?.oid} />

      <input type="hidden" name="retURL" value={fieldNames?.retURL} />

      {/*
       Debugging issues will be sent to this email
        SET to 0 - for deployment
        SET to 1 - for debugging
      */}
      <input type="hidden" name="debug" value="0" />

      <input type="hidden" name="debugEmail" value="sung.park@salesforce.com" />

      <input
        type="hidden"
        id={fieldNames?.brand}
        name={fieldNames?.brand}
        title="Brand"
        value="Avida"
      />

      <input
        type="hidden"
        id={fieldNames?.campaignCode}
        name={fieldNames?.campaignCode}
        maxLength={255}
        size={20}
        value=""
      />

      <input
        type="hidden"
        id={fieldNames?.preferredSeller}
        name={fieldNames?.preferredSeller}
        maxLength={100}
        size={20}
        value=""
      />

      <input
        type="hidden"
        id={fieldNames?.digitalLead}
        name={fieldNames?.digitalLead}
        value="1"
      />

      <input
        type="hidden"
        id={fieldNames?.otherThanAlisi}
        name={fieldNames?.otherThanAlisi}
        value="0"
      />
    </>
  )
}

export default HiddenFields
