import React, { memo, useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import PopupModal from '../PopupModal/PopupModal'
import { navigate } from '@reach/router'

import {
  FormContent,
  FormInput,
  FormSelect,
  FormTextArea,
  useFormState,
} from '../Form/Form'
import {
  CountryCode,
  PropertyCode,
  countryCodeName,
  projectCodeName,
  recordTypeOptions,
  isProd,
  isQA,
  isMaster,
  ActionUrl,
} from './SalesForceForm.utils'
import './SalesForceForm.scss'
import HiddenFields from './component/HiddenFields'
import SalesForceFormValidation from './SalesForceFormValidation'

const SalesForceForm = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const [formSuccess, setFormSuccess] = useState(
    queryParams.get('form-success'),
  )

  const isLocalEnv =
    process.env.GATSBY_SITE_URL === 'http://localhost:8000' ||
    process.env.NODE_ENV === 'development'

  const [isRecaptchaChecked, setIsRecaptchaChecked] = useState<boolean>(false)
  const [isAgreeChecked, setIsAgreeChecked] = useState<boolean>(false)
  const [isSuccessShown, setIsSuccessShown] = useState<boolean>(false)
  const [isCorporate, setIsCorporate] = useState<boolean>(false)

  const {
    // isFormDisplayed,
    values,
    errors,
    onFieldValueChange,
    onFieldBlur,
    // validateFields,
    // resetFields,
  } = useFormState({
    validation: SalesForceFormValidation,
  })

  useEffect(() => {
    const corporateRecordTypes = [
      '012Ip000000L4gI',
      '012In000000TSJM',
      '012F8000001PwVy',
      '012Ih000000PF9S',
    ]

    setIsCorporate(corporateRecordTypes.includes(values.recordType))

    if (formSuccess) {
      setIsSuccessShown(true)
    }
  }, [values.recordType, formSuccess])

  console.log('isPROD', isProd)
  console.log('isQa', isQA)
  console.log('isMaster', isMaster)
  console.log('ActionUrl', ActionUrl)
  console.log('Site URL', process.env.GATSBY_SITE_URL)

  return (
    <>
      <form action={ActionUrl?.url} method="POST" className="sales-force-form">
        <HiddenFields />

        <FormSelect
          id="recordType"
          name="recordType"
          placeholder="Inquiry Type"
          onChange={onFieldValueChange}
          onBlur={onFieldBlur}
          options={recordTypeOptions.filter((recordType) =>
            isProd
              ? recordType.env === 'prod'
              : isQA
                ? recordType.env === 'qa'
                : isMaster
                  ? recordType.env === 'master'
                  : recordType.env === 'dev',
          )}
          error={errors.project1}
          half={isCorporate}
        />

        {isCorporate && (
          <FormInput
            id="company"
            name="company"
            placeholder="Company"
            onChange={onFieldValueChange}
            onBlur={onFieldBlur}
            half
            // onChange={alphaOnly}
          />
        )}

        <FormInput
          id="first_name"
          name="first_name"
          placeholder="First Name"
          onChange={onFieldValueChange}
          onBlur={onFieldBlur}
          error={errors.first_name}
          half
          // onChange={alphaOnly}
        />

        <FormInput
          id="last_name"
          name="last_name"
          placeholder="Last Name"
          onChange={onFieldValueChange}
          onBlur={onFieldBlur}
          error={errors.last_name}
          half
          // onChange={alphaOnly}
        />

        <FormInput
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          onChange={onFieldValueChange}
          onBlur={onFieldBlur}
          error={errors.email}
          half
        />

        <FormSelect
          id={countryCodeName}
          name={countryCodeName}
          placeholder="Mobile Country Code"
          onChange={onFieldValueChange}
          onBlur={onFieldBlur}
          options={CountryCode}
          error={errors[countryCodeName]}
          half
        />

        <FormInput
          id="mobile"
          name="mobile"
          placeholder="Mobile"
          onChange={onFieldValueChange}
          onBlur={onFieldBlur}
          error={errors.mobile}
          half
          // onInput={numericOnly}
        />

        <FormSelect
          id={projectCodeName}
          name={projectCodeName}
          placeholder="Project"
          onChange={onFieldValueChange}
          onBlur={onFieldBlur}
          options={PropertyCode}
          error={errors[projectCodeName]}
          half
        />

        <FormTextArea
          id="description"
          name="description"
          placeholder="Description"
          onChange={onFieldValueChange}
          onBlur={onFieldBlur}
          // error={errors.description}
          rows={4}
        />

        <FormContent>
          <div className="sales-force-form__submit">
            <div className="sales-force-form__submit--privacy-notice">
              <label htmlFor="i-agree">
                <input
                  id="i-agree"
                  type="checkbox"
                  checked={isAgreeChecked}
                  readOnly
                />
                <span
                  role="checkbox"
                  onClick={(event): void => {
                    event.currentTarget.blur()
                    setIsAgreeChecked(!isAgreeChecked)
                  }}
                  onKeyPress={(event): void => {
                    if (event.which === 13) {
                      setIsAgreeChecked(!isAgreeChecked)
                    }
                  }}
                  tabIndex={0}
                  aria-checked={isAgreeChecked}
                  aria-labelledby="i-agree"
                  data-testid="policy"
                />
              </label>
              <div className="sales-force-form__submit--privacy-notice-text">
                I understand and agree with the{' '}
                <a href="/privacy-notice/" target="_blank">
                  Privacy Notice
                </a>{' '}
                and{' '}
                <a href="/terms-and-conditions/" target="_blank">
                  Terms & Conditions
                </a>
              </div>
            </div>
            <ReCAPTCHA
              sitekey={process.env.GATSBY_RECAPTCHA_API_KEY || ''}
              onChange={(value: string | null): void => {
                setIsRecaptchaChecked(!!value)
              }}
            />

            <input
              type="submit"
              className="sales-force-form__submit--btn"
              disabled={!isRecaptchaChecked && !isAgreeChecked}
              value="Submit"
            />
          </div>
        </FormContent>
      </form>

      <PopupModal
        isShowing={isSuccessShown}
        onClose={(): void => {
          setIsSuccessShown(false)
          navigate('/contact-us/')
        }}
        header="Thank you for your inquiry"
        body="Please expect our Customer Care representative to get in touch with you as soon as possible."
        footerLink
      />
    </>
  )
}

export default memo(SalesForceForm)
