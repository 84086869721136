import React, { useState } from 'react'
import VerticalNavigation, {
  NavItemTypes,
} from '../../components/VerticalNavigation/VerticalNavigation'
import { DeviceState, useDevice } from '../../helper/screenDevice'

const navUrls: NavItemTypes[] = [
  { label: 'Sales Inquiry', url: 'contact-us' },
  { label: 'After Sales', url: 'contact-us/after-sales' },
  {
    label: 'Other Ways to Contact Us',
    items: [
      { label: 'Head Office', url: 'contact-us/head-office' },
      { label: 'Satellite Office', url: 'contact-us/satellite-office' },
      {
        label: 'Showroom & Offices',
        url: 'contact-us/showroom/south-luzon',
      },
      { label: 'Country Codes', url: 'contact-us/country-code' },
    ],
  },
]

function ContactUsLayout({ children, className }: any) {
  const [device] = useState<DeviceState>(useDevice())

  return (
    <div className={className}>
      {device.isMobile ? (
        <VerticalNavigation
          navigations={navUrls}
          className="article-layout-aside-header"
        />
      ) : null}
      {children}
    </div>
  )
}

export default ContactUsLayout
