/* eslint-disable no-param-reassign */
import {
  hasValue,
  isEmail,
  isPhoneWithPrefix,
} from '../../services/validations'
import { countryCodeName, projectCodeName } from './SalesForceForm.utils'

type FieldTypes = { [key: string]: string }

type FieldValidationTypes = {
  values: FieldTypes
  fieldName: string
  errors: FieldTypes
}

const validateFirstName = ({
  values,
  fieldName,
  errors,
}: FieldValidationTypes): void => {
  const fieldValue = values[fieldName]

  if (!hasValue(fieldValue)) {
    errors[fieldName] = 'First name field is required.'
  }
}

const validateLastName = ({
  values,
  fieldName,
  errors,
}: FieldValidationTypes): void => {
  const fieldValue = values[fieldName]

  if (!hasValue(fieldValue)) {
    errors[fieldName] = 'Last name field is required.'
  }
}

const validateEmail = ({
  values,
  fieldName,
  errors,
}: FieldValidationTypes): void => {
  const fieldValue = values[fieldName]

  if (!hasValue(fieldValue)) {
    errors[fieldName] = 'Email field is required.'
    return
  }

  if (!isEmail(fieldValue)) {
    errors[fieldName] = 'Please input an email address.'
  }
}

const validatePhone = ({
  values,
  fieldName,
  errors,
}: FieldValidationTypes): void => {
  const fieldValue = values[fieldName]

  if (!hasValue(fieldValue)) {
    errors[fieldName] = 'Phone field is required.'
    return
  }

  // if (!isPhoneWithPrefix(fieldValue)) {
  //   errors[fieldName] = 'Please input a phone number.'
  // }
}

const validateCountry = ({
  values,
  fieldName,
  errors,
}: FieldValidationTypes): void => {
  const fieldValue = values[fieldName]

  if (!hasValue(fieldValue)) {
    errors[fieldName] = 'Country field is required.'
  }
}

const validateProject = ({
  values,
  fieldName,
  errors,
}: FieldValidationTypes): void => {
  const fieldValue = values[fieldName]

  if (!hasValue(fieldValue)) {
    errors[fieldName] = 'Project field is required.'
  }
}

// const validateDescription = ({
//   values,
//   fieldName,
//   errors,
// }: FieldValidationTypes): void => {
//   const fieldValue = values[fieldName]

//   if (!hasValue(fieldValue)) {
//     errors[fieldName] = 'Message field is required.'
//   }
// }

const SalesForceFormFormValidation = (values: {}): {} => {
  const errors = {}

  validateFirstName({ values, fieldName: 'first_name', errors })
  validateLastName({ values, fieldName: 'last_name', errors })
  validateEmail({ values, fieldName: 'email', errors })
  validatePhone({ values, fieldName: 'mobile', errors })
  validateCountry({ values, fieldName: countryCodeName, errors })
  validateProject({ values, fieldName: projectCodeName, errors })
  //   validateDescription({ values, fieldName: 'description', errors })

  return errors
}

export default SalesForceFormFormValidation
